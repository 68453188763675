export const BlogCard = ({ image, date, id, title, onClick }) => {
  return (
    <div className="col">
      <div className="card h-100 border-0 shadow-sm">
        <img
          src={image}
          className="card-img-top"
          alt={`Blog ${id}`}
          style={{
            maxWidth: "100%",
            height: "auto",
            maxHeight: "400px",
            objectFit: "cover",
          }}
        />
        <div className="card-body py-3 px-3">
          <p
            className="card-date mb-2"
            style={{
              fontSize: "18px",
              fontFamily: "Ubuntu",
              color: "#121212BF",
            }}
          >
            {date}
          </p>
          <p
            className="card-text"
            style={{
              fontSize: "20px",
              fontFamily: "Ubuntu",
              color: "#121212BF",
            }}
          >
            {title}
          </p>
          <button
            type="button"
            className="btn btn-blog"
            style={{
              fontSize: "16px",
              padding: "7px 18px",
              backgroundColor: "#8F1336",
              color: "#fff",
              fontFamily: "Ubuntu",
              borderRadius: "3px",
              border: "1px solid #8E1336",
              fontWeight: "700",
            }}
            onClick={onClick}
          >
            Read Full Blog
          </button>
        </div>
      </div>
    </div>
  );
};
