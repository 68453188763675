import React, { useState } from "react";
import { InputBox } from "../ChildComponent/Input/Inputs";
import { Button } from "../ChildComponent/Button/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { base_url } from "../utils/data";

const ContactUs = () => {
  const [addData, setAddData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const obj = validate();
    if (Object.keys(obj)?.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        // name,email,mobile,message
        body.append("name", addData?.name);
        body.append("email", addData?.email);
        body.append("mobile", addData?.mobile);
        body.append("message", addData?.message);
        const response = await fetch(base_url + "contact.php", {
          method: "POST",
          body: body,
        });
        const res = await response.json();
        if (res.success) {
          navigate("/");
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log("error ===", error);
      }
    } else {
      setAddData({ ...addData, ...obj });
    }
  };

  const validate = () => {
    const obj = {};
    if (!addData?.name || addData?.name?.trim()?.length == 0) {
      obj.name_err = "Please Enter Name";
    }
    if (!addData?.email || addData?.email?.trim()?.length == 0) {
      obj.email_err = "Please Enter Email";
    } else if (!/^\S+@\S+\.\S+$/.test(addData.email)) {
      obj.email_err = "A valid email address is required.";
    }
    if (!addData?.mobile || addData?.mobile?.trim()?.length == 0) {
      obj.mobile_err = "Please Enter Mobile";
    } else if (!/^\d{10}$/.test(addData.mobile)) {
      obj.mobile_err = "A valid 10-digit mobile number is required.";
    }
    if (!addData?.message || addData?.message?.trim()?.length == 0) {
      obj.message_err = "Please Enter Message";
    }
    return obj;
  };
  return (
    <div className="d-flex mt-5 w-100 h-100 justify-content-center align-content-center">
      <div
        className="d-flex mt-2  flex-column gap-3 px-4  py-3 justify-content-center align-content-center"
        style={{
          width: "auto",
          minWidth: "350px",
          maxWidth: "500px",
          boxShadow: "0px 0px 4px 0px #0000003D",
          textAlign: "center",
          borderRadius: "10px",
        }}
      >
        <h3>Get In Touch</h3>
        <div className="d-flex flex-column gap-3 justify-content-start">
          <InputBox
            error={addData?.name_err}
            value={addData?.name}
            onChange={(e) =>
              setAddData({ ...addData, name: e.target.value, name_err: "" })
            }
            placeholder={"Name"}
          />
          <InputBox
            error={addData?.email_err}
            value={addData?.email}
            onChange={(e) =>
              setAddData({ ...addData, email: e.target.value, email_err: "" })
            }
            placeholder={"Email"}
          />
          <InputBox
            error={addData?.mobile_err}
            value={addData?.mobile}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");
              if (value.length <= 10) {
                setAddData({
                  ...addData,
                  mobile: value,
                  mobile_err: "",
                });
              }
            }}
            placeholder={"Phone No."}
          />
          <InputBox
            error={addData?.message_err}
            value={addData?.message}
            onChange={(e) =>
              setAddData({
                ...addData,
                message: e.target.value,
                message_err: "",
              })
            }
            area={true}
            placeholder={"Message"}
          />
          <Button
            title={"Submit"}
            bg={"#8F1336"}
            color={"#ffffff"}
            disabled={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
