import React, { useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight, FaPlay } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { VideoCard } from "../../ChildComponent/OtherComponents";

// const Black = base_url + "upload/Black.svg";
// const Teil = base_url + "upload/Teil.svg";
// const Red = base_url + "upload/red.svg";
// const White = base_url + "upload/hover-white.svg";
// const HoverBlack =
//   base_url + "upload/hover-black.svg";
// const HoverRed = base_url + "upload/hover-red.svg";
// const categoryvideo =
//   base_url + "upload/categoryvideo.svg";

const CategoryTabs = ({ categories, activeTab, onTabClick }) => {
  // const uniqueCategories = Array.from(
  //   new Map(categories.map((item) => [item.label, item])).values()
  // );

  // console.log("uniqueCategories ===", uniqueCategories);

  return (
    <div
      className="d-flex justify-content-start gap-3 gap-md-3 px-2"
      style={{
        overflowX: "auto",
        paddingBottom: "20px",
        msOverflowStyle: "none" /* IE and Edge */,
        scrollbarWidth: "none" /* Firefox */,
        "&::-webkit-scrollbar": {
          display: "none",
        } /* Chrome, Safari and Opera */,
      }}
    >
      {categories.map((category, index) => (
        <React.Fragment key={index}>
          {category?.image?.length > 0 && (
            <span
              className={`tab ${activeTab === index ? "active-tab" : ""}`}
              onClick={() => onTabClick(index)}
              style={{
                // marginLeft: "-1.5%",
                cursor: "pointer",
                padding: "10px 10px",
                borderRadius: "20px",
                fontSize:
                  window.innerWidth < 768
                    ? activeTab === index
                      ? "20px"
                      : "16px"
                    : activeTab === index
                    ? "24px"
                    : "20px",
                color: activeTab === index ? "#8F1336" : "#121212BF",
                fontWeight: activeTab === index ? "bold" : "normal",
                whiteSpace: "nowrap",
              }}
            >
              {category?.label}
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

// ProductCard component with responsive improvements
const ProductCard = ({ image, title, onClick }) => (
  <div className="product-card d-flex flex-row" style={{ height: "100%" }}>
    <div
      style={{
        // position: "relative",
        // paddingTop: "125%", // 4:5 aspect ratio
        width: "100%",
        overflow: "hidden",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <img
        src={image}
        alt={title}
        className="rounded"
        style={{
          // position: "absolute",
          // top: 0,
          // left: 0,

          width: "100%",
          height: "90%",
          // maxHeight:"700px",
          objectFit: "cover",
        }}
      />
    </div>
    <div className="mt-3">
      <p
        className="mb-0"
        style={{
          fontSize: window.innerWidth < 768 ? "18px" : "24px",
          fontFamily: "Playfair Display",
          textAlign: "center",
          color: "#121212BF",
          fontWeight: "500",
        }}
      >
        {title}
      </p>
    </div>
  </div>
);

// VideoCard component with responsive improvements
const VideoCard = ({ url, title, onClick }) => {
  const [play, setPlay] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    setPlay(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  const handleStop = () => {
    setPlay(false);
    if (videoRef.current) {
      videoRef.current.pause(); // Pause the video
      // videoRef.current.currentTime = 0; // Reset playback to the beginning
    }
  };
  return (
    <div
      className="video-card position-relative"
      style={{
        position: "relative",
        paddingTop: "160%", // 16:26 aspect ratio
        width: "100%",
        overflow: "hidden",
        cursor: "pointer",
      }}
      // onClick={onClick}
    >
      <video
        ref={videoRef}
        onClick={() => {
          if (play) {
            handleStop();
          }
        }}
        src={url}
        className="img-fluid mb-2 rounded"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        loop
        controls={false}
        muted={false}
        playsInline={false}
        preload="metadata"
        // onClick={onClick}
      >
        {/* Fallback Thumbnail */}
        {/* <img
        src="path/to/fallback-thumbnail.jpg"
        alt="Video Thumbnail"
        className="img-fluid mb-2 rounded"
        style={{
          height: "100px",
          width: "100%",
          objectFit: "cover",
        }}
        onClick={onClick}
      /> */}
      </video>
      {!play && (
        <div className="play_btn" onClick={handlePlay}>
          <FaPlay color="#fff" size={20} />
        </div>
      )}
    </div>
  );
};

// Main ShopByCategories component
const ShopByCategories = () => {
  const { shop_by_category } = useSelector((state) => state.otherData);
  const [activeTab, setActiveTab] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  console.log("activeTab ===", activeTab);
  console.log("shop_by_category ===", shop_by_category);

  const handleTabClick = (category) => {
    setActiveTab(category);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? shop_by_category[0]?.image.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === shop_by_category[0]?.image.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleNavigate = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="container-fluid mt-4 px-3 px-md-5 w-100">
      <h2
        className="mb-4 mt-5 fw-600 "
        style={{
          fontSize:" clamp(28px, 4vw, 40px)",
          fontFamily: "Playfair Display",
          color: "#121212BF",
          fontSize: window.innerWidth < 768 ? "32px" : "40px",
          fontWeight: "600",
        }}
      >
        Shop By Categories
      </h2>
      <CategoryTabs
        categories={shop_by_category}
        activeTab={activeTab}
        onTabClick={handleTabClick}
      />

      <div
        className="row g-4"
        // style={{
        //   display:'flex',
        //   flexDirection:'row-reverse'
        // }}
      >
        {/* First Product Card */}
        <div className="col-6 col-md-6 col-lg-4 px-1 py-1 position-relative">
          <ProductCard
            image={
              shop_by_category?.[activeTab]?.image[currentImageIndex]?.image
            }
            title={
              shop_by_category?.[activeTab]?.image[currentImageIndex]?.title
            }
            onClick={() => {
              handleNavigate(
                shop_by_category[activeTab]?.image[currentImageIndex]
                  ?.product_id
              );
            }}
          />
          <div
            className="d-none d-lg-flex justify-content-end position-absolute w-100"
            style={{ bottom: "0", gap: "16px", right: "6px" }}
          >
            <button
              className="prev-button btn btn-secondary"
              onClick={goToPreviousImage}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#C1C1C1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaAngleLeft size={24} />
            </button>
          </div>
        </div>

        {/* Second Product Card */}
        <div className="col-6 col-md-6 col-lg-4 px-1 py-1 position-relative">
          <ProductCard
            image={
              shop_by_category?.[activeTab]?.image[
                (currentImageIndex + 1) %
                  shop_by_category[activeTab]?.image?.length
              ]?.image
            }
            title={
              shop_by_category?.[activeTab]?.image[
                (currentImageIndex + 1) %
                  shop_by_category?.[activeTab]?.image?.length
              ]?.title
            }
            onClick={() => {
              handleNavigate(
                shop_by_category[activeTab]?.image[
                  (currentImageIndex + 1) %
                    shop_by_category[activeTab]?.image.length
                ]?.product_id
              );
            }}
          />
          <div
            className="d-none d-lg-flex justify-content-start position-absolute w-100"
            style={{ bottom: "0", gap: "16px", left: "6px" }}
          >
            <button
              className="next-button btn btn-secondary"
              onClick={goToNextImage}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#8E1336",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaAngleRight size={24} color="white" />
            </button>
          </div>
        </div>

        {/* Video Card with Navigation */}
        <div className="col-12 col-lg-4 position-relative">
          <VideoCard
            url={shop_by_category[activeTab]?.video?.[0]?.video}
            onClick={() => {
              handleNavigate(shop_by_category[activeTab]?.video[0]?.product_id);
            }}
          />

          {/* Desktop Navigation Buttons */}
          {/* <div
            className="d-none d-lg-flex justify-content-start position-absolute w-100"
            style={{ bottom: "-7px", gap: "16px", right: "113%" }}
          >
            <button
              className="prev-button btn btn-secondary"
              onClick={goToPreviousImage}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#C1C1C1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaAngleLeft size={24} />
            </button>
            <button
              className="next-button btn btn-secondary"
              onClick={goToNextImage}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#8E1336",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaAngleRight size={24} color="white" />
            </button>
          </div> */}

          {/* Mobile Navigation Buttons */}
          <div
            className="d-flex d-lg-none justify-content-center mt-4"
            style={{ gap: "16px" }}
          >
            <button
              className="prev-button btn btn-secondary"
              onClick={goToPreviousImage}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#C1C1C1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaAngleLeft size={24} />
            </button>
            <button
              className="next-button btn btn-secondary"
              onClick={goToNextImage}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#8E1336",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaAngleRight size={24} color="white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopByCategories;
