import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import NavHeading from "../Navheading/NavHeading";
import NavBar from "../Navbar/Navbar";
import { base_url } from "../../utils/data";
import { toast } from "react-toastify";
import store from "../../Redux/store";
import { get_personal_details } from "../../Redux/Slice/UserSlice";
import { useSelector } from "react-redux";

export default function PersonalDetails() {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("view");

  const [errorData, setErrorData] = useState({});

  const {user_details,user_id} = useSelector((state)=>state.userData)

  console.log("user_details ===", user_details);

  // Fetch user details on component mount
  useEffect(() => {
    fetchData();
    // store.dispatch(get_personal_details(userId));
  }, []);

  const fetchData = async () => {
    if (user_id) {
      const formData = new FormData();
      formData.append("action", "user_personal_details");
      formData.append("userid", user_id);

      fetch(base_url + `user.php`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success && data.message.length > 0) {
            const { fullname, email, mobile } = data.data;
            setType("view");
            setFirstName(fullname);
            // setLastName(last_name);
            setEmail(email);
            setMobileNumber(mobile);
          } else {
            console.warn("User details not found:", data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    const validation = validate();

    console.log("validation ===", validation);

    if (Object.keys(validation) == 0) {
      const userId = localStorage.getItem("user_id");
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("userid", userId);
      formData.append("fullname", firstName);
      formData.append("email", email);
      formData.append("mobile", mobileNumber);

      fetch(base_url + `user.php`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setType("view");
            fetchData();
            toast.success(data.message);
            // alert("Details updated successfully!");
          } else {
            toast.error(data.message);
            // alert("Error updating details: " + data.message);
          }
        })
        .catch((error) => {
          console.error("Error updating details:", error);
        });
    } else {
      setErrorData(validation);
    }
  };

  const validate = () => {
    let errors = {};
    if (!firstName || firstName.trim() === "") {
      errors.first_name_error = "Full Name is required.";
    }
    if (!email) {
      errors.email_error = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email_error = "A valid email address is required.";
    }
    if (!mobileNumber) {
      errors.mobilenumber_error = "Mobile Number is required.";
    } else if (!/^\d{10}$/.test(mobileNumber)) {
      errors.mobilenumber_error = "A valid 10-digit mobile number is required.";
    }

    return errors;
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while fetching data
  }

  return (
    <Row className="g-4 ">
      <Col
        md={12}
        className="d-flex justify-content-between align-items-center"
      >
        <h4 style={{ color: "#121212BF" }}>Personal Details</h4>
      </Col>
      <Col md={12}>
        <Card className="d-flex p-4 px-5 shadow-sm">
          <div className="d-flex justify-content-between w-100 align-items-center py-3">
            <h5 style={{ color: "#121212BF" }}>Edit Details</h5>
          </div>
          {/* <Form> */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formFirstName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  error={errorData?.first_name_error}
                  disabled={type == "view"}
                  type="text"
                  placeholder="Enter Full name"
                  value={firstName} // Controlled input
                  // onChange={(e) => setFirstName(e.target.value)}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setErrorData({ ...errorData, first_name_error: "" });
                  }}
                />
                {errorData?.first_name_error && (
                  <p
                    style={{
                      fontSize: "14px",
                      color: "red",
                      marginBottom: "0",
                    }}
                  >
                    {errorData?.first_name_error}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  error={errorData?.email_error}
                  disabled={type == "view"}
                  type="email"
                  placeholder="Enter email"
                  value={email} // Controlled input
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={(e) => {
                    setEmail(e.target.value?.trim());
                    setErrorData({ ...errorData, email_error: "" });
                  }}
                />
                {errorData?.email_error && (
                  <p
                    style={{
                      fontSize: "14px",
                      color: "red",
                      marginBottom: "0",
                    }}
                  >
                    {errorData?.email_error}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formMobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  error={errorData?.mobilenumber_error}
                  disabled={type == "view"}
                  type="text"
                  placeholder="Enter mobile number"
                  value={mobileNumber} // Controlled input
                  onChange={(e) => {
                    const value = e.target.value?.trim();
                    if (/^\d{0,10}$/.test(value)) {
                      setMobileNumber(value);
                      setErrorData({ ...errorData, mobilenumber_error: "" });
                    }
                  }}
                />
                {errorData?.mobilenumber_error && (
                  <p
                    style={{
                      fontSize: "14px",
                      color: "red",
                      marginBottom: "0",
                    }}
                  >
                    {errorData?.mobilenumber_error}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            {type == "view" ? (
              <>
                <Button
                  type="submit" // Set button type to submit
                  variant="outline px-5 py-2"
                  style={{
                    backgroundColor: "#ffffff",
                    border: "2px solid #8E1336",
                    color: "#8E1336",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    setType("edit");
                  }}
                >
                  Edit
                </Button>
              </>
            ) : (
              <div className="d-flex gap-2">
                <Button
                  type="submit" // Set button type to submit
                  variant="outline px-5 py-2"
                  style={{
                    backgroundColor: "#ffffff",
                    border: "2px solid #8E1336",
                    color: "#8E1336",
                    fontSize: "15px",
                  }}
                  onClick={() => handleSubmit()}
                >
                  Update
                </Button>
                <Button
                  type="submit" // Set button type to submit
                  variant="outline px-5 py-2"
                  style={{
                    backgroundColor: "#8E1336",
                    border: "2px solid #8E1336",
                    color: "#fff",
                    fontSize: "15px",
                  }}
                  onClick={() => {
                    setType("view");
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          {/* </Form> */}
        </Card>
      </Col>
    </Row>
  );
}
