import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import NavHeading from "../Navheading/NavHeading";
import NavBar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import bagImage from "../../assets/emptywishlist.svg";

import "bootstrap/dist/css/bootstrap.css";
import { useSelector } from "react-redux";
import store from "../../Redux/store";
import {
  get_wish_list,
  update_wish_list,
} from "../../Redux/Slice/ProductSlice";
import { toast } from "react-toastify";
import {
  ProductCardSub,
  StarRating,
} from "../../ChildComponent/OtherComponents";
import { base_url } from "../../utils/data";

export default function Wishlist() {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const { wish_list } = useSelector((state) => state.productData);

  useEffect(() => {
    store.dispatch(get_wish_list(userId));
  }, []);

  return (
    <div>
      <div className=" py-2 py-lg-3  py-xl-5">
        <h2
          className="wishlist-heading text-left  px-2 px-lg-3  px-xl-5 mb-4 fs-1"
          style={{ fontSize: "clamp(28px, 4vw, 40px)" }}
        >
          My Wishlist
        </h2>
        {wish_list?.length === 0 ? (
          <div className="container d-flex flex-column justify-content-center align-items-center">
            {/* Bag Image */}
            <img
              src={bagImage}
              alt="Bag"
              // className="position-relative"
              style={{
                width: "150px",
                height: "auto",
                zIndex: "2",
              }}
            />

            <div className="text-center mt-5">
              <p className="fs-5" style={{ color: "#1d1716" }}>
                Your wishlist is empty, but your dream dress is waiting to be
                found!
              </p>
              <button
                className="btn"
                style={{
                  border: "2px solid #8F1336",
                  color: "#8F1336",
                  transition: "all 0.3s ease",
                  padding: "8px 24px",
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#8F1336";
                  e.target.style.color = "white";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "transparent";
                  e.target.style.color = "#8F1336";
                }}
                onClick={() => navigate("/")}
              >
                Start Shopping
              </button>
            </div>
          </div>
        ) : (
          <div className="row product_list_wish px-2 px-lg-3  px-xl-5">
            {wish_list?.map((item, index) => (
              <ProductCardSub
                className={"product-card h-100"}
                imgH={
                  "auto"
                  // window.innerWidth <= 560
                  //   ? "300px"
                  //   : window.innerWidth <= 560
                  //   ? "250px"
                  //   : window.innerWidth <= 1000
                  //   ? "400px"
                  //   : "500px"
                }
                id={item?.product_id}
                key={index}
                product={item}
                // ofit={
                //   window?.innerWidth >= 1600
                //     ? "contain"
                //     : window?.innerWidth >= 500
                //     ? "contain"
                //     : "cover"
                // }
                ofit={"cover"}
              />
            ))}
          </div>
        )}
        {/* </div> */}
      </div>
      <Footer />
      <Banner />
    </div>
  );
}
