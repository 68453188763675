import React, { useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import store from "../../Redux/store";
import { get_user_id } from "../../Redux/Slice/UserSlice";
import { Drawer } from "../../ChildComponent/Drawer/Drawer";
import { toast } from "react-toastify";

const Sidebar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current URL path

  const menuItems = [
    { name: "Overview", path: "/dashboard" },
    { name: "Addresses", path: "/dashboard/address" },
    { name: "Orders", path: "/dashboard/orders" },
    // { name: "Payment Methods", path: "/dashboard/PaymentMethods" },
    { name: "Personal Details", path: "/dashboard/PersonalDetails" },
    { name: "Log Out", path: "/Logout" },
  ];

  const handleItemClick = (item) => {
    setOpenDrawer(false);
    if (item.name === "Log Out") {
      localStorage.clear();
      toast.success("Log Out Successfully")
      store.dispatch(get_user_id(""));
      navigate("/");
      return;
    }
    navigate(item.path);
  };

  return (
    <Navbar
      expand="xl"
      className="flex-column p-0 align-items-center  "
      style={{ padding: "0px 0px" }}
    >
      <div className="profile_sidebar_toggle">
        <Navbar.Toggle onClick={() => setOpenDrawer(true)} />
      </div>
      <Nav className="flex-column profile_sidebar">
        {menuItems.map((item, index) => (
          <Button
            key={index}
            variant="outline"
            className="my-2"
            style={{
              width: "187px",
              height: "51px",
              fontSize: "17px",
              border: "1px solid #000000",
              backgroundColor:
                location.pathname === item.path ? "#8E1336" : "#ffffff", // Highlight active page
              color: location.pathname === item.path ? "#ffffff" : "#000000", // Text color for active page
            }}
            onClick={() => handleItemClick(item)}
          >
            {item.name}
          </Button>
        ))}
      </Nav>
      {openDrawer && (
        <Drawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          title={""}
          size={"220px"}
        >
          <Nav className="flex-column">
            {menuItems.map((item, index) => (
              <Button
                key={index}
                variant="outline"
                className="my-2"
                style={{
                  width: "187px",
                  height: "51px",
                  fontSize: "17px",
                  border: "1px solid #000000",
                  backgroundColor:
                    location.pathname === item.path ? "#8E1336" : "#ffffff", // Highlight active page
                  color:
                    location.pathname === item.path ? "#ffffff" : "#000000", // Text color for active page
                }}
                onClick={() => handleItemClick(item)}
              >
                {item.name}
              </Button>
            ))}
          </Nav>
        </Drawer>
      )}
    </Navbar>
  );
};

export default Sidebar;
