import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { FiEye, FiEyeOff } from "react-icons/fi";

export const Dropdown = ({ children, label, value, onChange, error }) => {
  return (
    <div
      className="btn-group d-flex flex-column gap-1"
      style={{ width: "100%", flex: 1 }}
    >
      {label && <label htmlFor="size-select">{label} </label>}
      <select
        id="size-select"
        value={value}
        onChange={onChange}
        style={{
          border: `1px solid ${error ? "red" : "gray"}`,
          minWidth: "100%",
          borderRadius: "4px",
          marginTop: "1px",
          padding: "5px 0",
        }}
      >
        {children}
      </select>
      {error && <p style={{ color: "red", marginBottom: "0" }}>{error}</p>}
    </div>
  );
};

export const CheckBox = ({ checked, label, value, onChange }) => {
  return (
    <div className="btn-group" style={{ width: "100%", flex: 1 }}>
      {label && <label htmlFor="size-select">{label} </label>}
      <input
        type="checkbox"
        checked={checked}
        value={value}
        onChange={onChange}
        style={{ cursor: "pointer", height: "15px", width: "15px" }}
      />
    </div>
  );
};

export const InputBox = ({
  pass,
  type = "text",
  label,
  value,
  onChange,
  error,
  placeholder,
  width,
  rightIcon,
  leftIcon,
  disabled,
  mess,
  area = false,
}) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <div
      className="btn-group d-flex flex-column gap-1 align-items-start"
      style={{ maxWidth: width ? width : "100%", flex: 1 }}
    >
      {label && (
        <label htmlFor="size-select" className="fs-18px fw-bold">
          {label}{" "}
        </label>
      )}

      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        {leftIcon && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: "10px",
              cursor: "pointer",
            }}
          >
            {leftIcon}
          </div>
        )}
        {area ? (
          <textarea
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={onChange}
            style={{
              width: "100%",
              borderRadius: "4px",
              marginTop: "1px",
              padding: "5px 10px",

              border: `1px solid ${error ? "red" : "gray"}`,
            }}
          />
        ) : (
          <input
            disabled={disabled}
            placeholder={placeholder}
            type={pass && !area && !showPass ? "password" : type}
            value={value}
            onChange={onChange}
            style={{
              width: "100%",
              borderRadius: "4px",
              marginTop: "1px",
              padding: "5px 10px",
              paddingLeft: leftIcon ? "40px" : "10px",
              border: `1px solid ${error ? "red" : "gray"}`,
            }}
          />
        )}
        {pass && !area ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: "15px",
              cursor: "pointer",
            }}
          >
            {showPass ? (
              <FiEyeOff onClick={() => setShowPass(!showPass)} size={20} />
            ) : (
              <FiEye onClick={() => setShowPass(!showPass)} size={20} />
            )}
          </div>
        ) : (
          <>
            {rightIcon && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "15px",
                  cursor: "pointer",
                }}
              >
                {rightIcon}
              </div>
            )}
          </>
        )}
      </div>
      {mess && (
        <p style={{ fontSize: "14px", color: "black", marginBottom: "0" }}>
          {mess}
        </p>
      )}
      {error && (
        <p style={{ fontSize: "14px", color: "red", marginBottom: "0" }}>
          {error}
        </p>
      )}
    </div>
  );
};
