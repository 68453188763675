import React from "react";
import { FaPhone, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { FaPhoneFlip } from "react-icons/fa6";
import { base_url, socialImages } from "../../utils/data";

// Reusable SectionHeader component
const SectionHeader = ({ title }) => {
  return (
    <h4
      className="mb-2"
      style={{
        fontFamily: "Playfair Display",
        color: "#121212BF",
        fontWeight: 500,
        fontSize: "24px",
      }}
    >
      {title}
    </h4>
  );
};

// Reusable List component
const List = ({ items }) => {
  return (
    <ul className="list-unstyled">
      {items.map((item, index) => (
        <li
          key={index}
          className="mb-2"
          style={{
            fontFamily: "Ubuntu",
            color: "#121212BF",
            fontSize: "16px",
            cursor: "pointer",
            wordBreak: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

// Footer Component
const Footer = () => {
  const logo = base_url + "upload/logo.svg";

  return (
    <div className="container-fluid py-5 px-2 px-lg-5">
      <div className="mb-4">
        <img
          src={logo}
          className="mb-3"
          alt="footer-img"
          style={{ width: "100px" }}
        />
      </div>

      {/* Desktop view (above 770px) */}
      <div className="row d-none d-md-flex">
        <div className="col-md-3 col-12 mb-4">
          <SectionHeader title="About Us" />
          <List
            items={["Blogs", "Collection", "Site Map", "Book", "Appointment"]}
          />
        </div>

        <div className="col-md-3 col-12 mb-4">
          <SectionHeader title="Terms & Policies" />
          <List
            items={[
              "Terms & Conditions",
              "Cookie Policy",
              "Return Policy",
              "Exchange Policy",
              "Refund Policy",
            ]}
          />
        </div>

        <div className="col-md-3 col-12 mb-4">
          <SectionHeader title="Help & Support" />
          <List items={["Order Tracking", "FAQ's"]} />
        </div>

        <div className="col-md-3 col-12 mb-4">
          <SectionHeader title="Follow Us On" />
          <div className="d-flex gap-2 mb-3">
            {socialImages.map((social, index) => (
              <a
                key={index}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={social.src}
                  className="iconimages"
                  alt={social.alt}
                  style={{ width: "29px" }}
                />
              </a>
            ))}
          </div>
          <SectionHeader title="Contact Us" />
          <List
            items={[
              <a
                href="tel:9177771077"
                key="phone"
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <FaPhoneAlt className="me-2 text-danger" /> +91 91777 71077
              </a>,
              <a
                href="mailto:shilohbridals@gmail.com"
                key="email"
                style={{
                  display: "flex",
                  alignItems: "center",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <FaEnvelope
                  className="me-2 text-danger"
                  style={{ flexShrink: 0 }}
                />
                <span style={{ wordBreak: "break-word" }}>
                  shilohbridals@gmail.com
                </span>
              </a>,
            ]}
          />
        </div>
      </div>

      {/* Mobile view (770px to 300px) */}
      <div className="d-md-none">
        {/* Row 1 */}
        <div className="row">
          <div className="col-6 mb-4">
            <SectionHeader title="About Us" />
            <List
              items={["Blogs", "Collection", "Site Map", "Book", "Appointment"]}
            />
          </div>
          <div className="col-6 mb-4">
            <SectionHeader title="Terms & Policies" />
            <List
              items={[
                "Terms & Conditions",
                "Cookie Policy",
                "Return Policy",
                "Exchange Policy",
                "Refund Policy",
              ]}
            />
          </div>
        </div>

        {/* Row 2 */}
        <div className="row">
          <div className="col-6 mb-4">
            <SectionHeader title="Help & Support" />
            <List items={["Order Tracking", "FAQ's"]} />
          </div>
          <div className="col-6 mb-4">
            <SectionHeader title="Follow Us On" />
            <div className="d-flex gap-2 mb-3">
              {socialImages.map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={social.src}
                    className="iconimages"
                    alt={social.alt}
                    style={{ width: "29px" }}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Row 3 - Contact Us in its own row */}
        <div className="row">
          <div className="col-12 mb-4">
            <SectionHeader title="Contact Us" />
            <List
              items={[
                <a
                  href="tel:9177771077"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  <FaPhoneAlt className="me-2 text-danger" />
                  {/* <a
                    href="tel:9177771077"
                    style={{
                      textDecoration: "none",
                      color: "inherit", 
                    }}
                  > */}
                  +91 91777 71077
                  {/* </a> */}
                </a>,
                <a
                  href="mailto:shilohbridals@gmail.com"
                  key="email"
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    fontSize: "18px",
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    justifyContent: "flex-start",
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  <FaEnvelope
                    className="me-2 text-danger"
                    style={{ flexShrink: 0, marginTop: "4px" }}
                  />
                  shilohbridals@gmail.com
                </a>,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
