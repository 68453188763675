import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import SizeGuide from "../SizeGuide/SizeGuide";
import QtyInput from "../Quantity/Quantity";
import { Link } from "react-router-dom";
import bagImage from "../../assets/emptycart.svg";
import CheckoutSummary from "./CheckoutSummary";
import { CartCard } from "../../ChildComponent/OtherComponents";
import { CustomModal } from "../../ChildComponent/Modal/Modal";
import { ReduceFunction } from "../../utils/Hook/OtherFunction";
import store from "../../Redux/store";
import {
  delete_cart_item,
  get_cart_list,
  move_to_wish_list,
  update_selected_cart_product,
} from "../../Redux/Slice/ProductSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CheckBox } from "../../ChildComponent/Input/Inputs";
import DebounceHook from "../../utils/Hook/DebouncingHook";
import { base_url } from "../../utils/data";

// const delivery = base_url + "upload/delivery.png";
// const arrow = base_url + "upload/arrow.png";
// const uplevel = base_url + "upload/uplevel.png";
// const measure = base_url + "upload/measure.png";

export default function Cart() {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState({}); // Track pending updates

  const userId = localStorage.getItem("user_id");

  const { cart_list, cart_loading, selected_card_product } = useSelector(
    (state) => state.productData
  );

  useEffect(() => {
    setCartItems(cart_list);
  }, [cart_list]);
  // console.log("selected_card_product ===", selected_card_product);

  const updateCartItemDebounced = useCallback(
    DebounceHook((itemId, size, count) => {
      updateCartItem(itemId, size, count);
    }, 500),
    []
  );

  const handleDelete = async (itemId) => {
    if (
      !window.confirm(
        "Are you sure you want to remove this item from your cart?"
      )
    ) {
      return;
    }
    const obj = { userId, itemId };

    store
      .dispatch(delete_cart_item(obj))
      .then((val) => {
        const res = val?.payload;
        console.log(res.success);
        if (res.success) {
          store.dispatch(get_cart_list(userId));
          toast.success(res.message);
        }
      })
      .catch((error) => {
        console.log("error ===", error);
      });
  };

  const updateCartItem = async (itemId, size, count) => {
    if (updateInProgress) return;

    try {
      setUpdateInProgress(true);
      const userId = localStorage.getItem("user_id");
      const formData = new FormData();
      formData.append("action", "update");
      formData.append("user_id", userId);
      formData.append("id", itemId);
      formData.append("size", size);
      formData.append("count", count);

      const response = await fetch(
        base_url + "cart.php",
        { method: "POST", body: formData }
      );

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();

      if (data?.success) {
        store.dispatch(get_cart_list(userId));
      } else if (!data.success) {
        throw new Error(data.message || "Failed to update cart item");
      }
    } catch (error) {
      console.error("Error updating cart item:", error);
      alert("Failed to update cart. Please try again.");
    } finally {
      setUpdateInProgress(false);
    }
  };

  const MoveToWishList = async (id) => {
    if (
      !window.confirm(
        "Are you sure you want to move this item from your cart to wish list?"
      )
    ) {
      return;
    }
    store
      .dispatch(move_to_wish_list(id))
      .then((val) => {
        const res = val?.payload;
        console.log(res.success);
        if (res.success) {
          store.dispatch(get_cart_list(userId));
          toast.success(res.message);
        }
      })
      .catch((error) => {
        console.log("error ===", error);
      });
  };

  const handleSizeChange = (itemId, newSize) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, Size: newSize } : item
      )
    );

    setPendingUpdate((prev) => ({
      ...prev,
      [itemId]: { ...prev[itemId], Size: newSize },
    }));

    const item = cartItems.find((item) => item.id === itemId);
    const updatedCount = item ? item.count : 1; // Fallback to 1 if item not found

    updateCartItemDebounced(itemId, newSize, updatedCount);
  };

  const handleQuantityChange = (itemId, newCount) => {
    if (newCount < 1) return;

    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, count: newCount } : item
      )
    );

    setPendingUpdate((prev) => ({
      ...prev,
      [itemId]: { ...prev[itemId], count: newCount },
    }));

    const item = cartItems.find((item) => item.id === itemId);
    const updatedSize = item ? item.Size : null; // Fallback to null if item not found

    updateCartItemDebounced(itemId, updatedSize, newCount);
  };

  // Optional: Sync state changes with an effect to handle edge cases
  useEffect(() => {
    Object.entries(pendingUpdate).forEach(([itemId, { Size, count }]) => {
      updateCartItemDebounced(itemId, Size, count);
    });
  }, [pendingUpdate, updateCartItemDebounced]);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <React.Fragment>
      {/* <div className="card my-3">
        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>Check delivery time & services</span>
            <form className="w-50">
              <input
                className="form-control"
                type="search"
                placeholder="Enter Your Address"
              />
            </form>
          </li>
        </ul>
      </div> */}

      {/* <div className="card my-3">
        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span className="d-flex gap-2 align-items-center ">
              <CheckBox
                checked={selected_card_product?.length == cartItems?.length}
                onChange={(e) => {
                  store.dispatch(update_selected_cart_product({ type: "all" }));
                }}
              />
              1/1 Item Selected
            </span>
            <h6 className="d-flex">Remove</h6>
            <h6>Move to Wishlist</h6>
          </li>
        </ul>
      </div> */}

      {cartItems.length > 0 ? (
        cartItems.map((item, i) => (
          <CartCard
            key={i}
            item={item}
            handleDelete={handleDelete}
            QTYChange={handleQuantityChange}
            SizeChange={handleSizeChange}
            toggleModal={toggleModal}
            MoveToWishList={MoveToWishList}
            CheckValue={
              selected_card_product?.filter((v) => v?.id == item.id)?.length > 0
            }
            CheckOption={() => {
              store.dispatch(
                update_selected_cart_product({ type: "single", id: item.id })
              );
            }}
          />
        ))
      ) : (
        <div className="alert alert-warning">Your cart is empty.</div>
      )}
      {isModalOpen && (
        <CustomModal
          open={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}
        >
          <SizeGuide closeModal={toggleModal} />
        </CustomModal>
      )}
    </React.Fragment>
  );
}
