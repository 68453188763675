import React, { useEffect, useState } from "react";
import "./Gallery.css";
import { useSelector } from "react-redux";
import { CustomModal } from "../../ChildComponent/Modal/Modal";

const Gallery = () => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const { gallary_list } = useSelector((state) => state.otherData);

  return (
    <div className="container-fluid px-2 px-lg-5 mt-5">
      <h2 className="gallery-title">Gallery</h2>
      <div className="image-list" style={{ width: "100%" }}>
        {gallary_list?.map((item, i) => (
          <div className="image-item" key={i}>
            <img
              onClick={() => {
                setUrl(item.image_url);
                setOpen(true);
              }}
              src={item.image_url}
              alt={item.title}
              className="gallery-image"
              style={{
                width: "200px",
                height: "300px",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          </div>
        ))}
      </div>
      <CustomModal
        width="400px"
        height="400px"
        open={open}
        title={"Gallary"}
        handleCancel={() => setOpen(false)}
      >
        <div className="d-flex justify-content-center align-items-center">
          <img
            onClick={() => setOpen(true)}
            src={url}
            alt={"Gallary"}
            // className="gallery-image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default Gallery;
