import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";
import store from "../../Redux/store";
import { get_personal_details } from "../../Redux/Slice/UserSlice";
import { useSelector } from "react-redux";

const ProfileLayout = () => {
  const {user_id} = useSelector((state)=>state.userData)

  useEffect(() => {
    store.dispatch(get_personal_details(user_id));
  }, [user_id]);
  return (
    <div className="dashboard_main">
      <Container fluid className="dashboard-container">
        <h3
          className="py-2"
          style={{ textAlign: "center", color: "#121212BF" }}
        >
          My Account
        </h3>
        <Row className="dashboard">
          <Col
            xl={2}
            lg={12}
            className=" px-lg-1 justify-content-end align-items-end "
          >
            <Sidebar />
          </Col>
          <Col className=" pl-0 pl-lg-2" xl={10} md={12}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileLayout;
