import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { VideoCard } from "../../ChildComponent/OtherComponents";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../utils/data";

const MainImage = ({ src, alt }) => {
  return <VideoCard maxW={"800px"} url={src} />;
};

const Thumbnail = ({ src, alt, onClick }) => {
  return (
    <div
      className="thumbnail-wrapper thwrapper" // Added h-100
      style={{
        overflow: "hidden",
        marginBottom: "1rem", // Reduced margin
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        height: "340px", // Fixed height
        "@media screen and (max-width: 991px)": {
          height: "200px", // Adjusted height for mobile
          height: "20px", // Fixed height
        },
      }}
    >
      <img
        className="thumbnail-image img-fluid " // Added h-100
        src={src}
        alt={alt}
        onClick={onClick}
        style={{
          cursor: "pointer",
          objectFit: "contain",
          objectPosition: "center", // Center the image
          display: "block",
          imageRendering: "-webkit-optimize-contrast",
          transition: "transform 0.2s ease",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      />
    </div>
  );
};

const Collection = ({
  title,
  mainImageSrc,
  mainImageAlt,
  thumbnails,
  buttonText,
  navigate,
  ButtonClick,
}) => {
  return (
    <div className="container-fluid px-4 px-lg-5 my-4 mt-5">
      <h2
        className="text-start mb-4"
        style={{
          fontSize: "clamp(28px, 4vw, 40px)", // Responsive font size
          fontWeight: "600",
          fontFamily: "Playfair Display",
          color: "#121212BF",
        }}
      >
        {title}
      </h2>
      <div className="row g-4">
        {" "}
        {/* Added gap between rows */}
        <div className="col-lg-6 mb-4 mb-lg-0">
          {" "}
          {/* Added margin bottom for mobile */}
          <MainImage src={mainImageSrc} alt={mainImageAlt} />
        </div>
        <div className="col-lg-6">
          <div className="row row-cols-2 g-3">
            {" "}
            {/* Reduced gap between thumbnails */}
            {thumbnails?.slice(0, 4)?.map((thumbnail, index) => (
              <div className="col mb-2" key={index}>
                {" "}
                {/* Added margin bottom */}
                <Thumbnail
                  src={thumbnail.image}
                  alt={thumbnail.image}
                  onClick={() => {
                    navigate(`/product/${thumbnail?.product_id}`);
                  }}
                />
              </div>
            ))}
          </div>
          {/* Button below thumbnails */}
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              onClick={ButtonClick}
              className="btn px-4 py-2 text-white"
              style={{
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Ubuntu, sans-serif",
                backgroundColor: "#8E1336",
                boxShadow: "0 2px 4px rgba(142,19,54,0.2)",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "#a01741",
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 8px rgba(142,19,54,0.3)",
                },
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CollectionData = () => {
  const { bridal_collection } = useSelector((state) => state.otherData);
  const navigate = useNavigate();

  return bridal_collection ? (
    <Collection
      title="Bridal Collection"
      mainImageSrc={bridal_collection?.video?.[0]?.video}
      mainImageAlt="Bridal Dress"
      thumbnails={bridal_collection?.image}
      buttonText="View All"
      ButtonClick={() =>
        navigate(
          `/ShopByCategories/${bridal_collection?.id}/${bridal_collection?.sub_category_id}`,
          {
            state: {
              data: {
                id: bridal_collection?.id,
                cat_id: bridal_collection?.sub_category_id,
              },
            },
          }
        )
      }
      navigate={navigate}
    />
  ) : (
    ""
  );
};

export default CollectionData;
