import React, { useState } from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";

export default function ContactHeading() {
  const phoneNumber = "+91 91777 71077"; // Make sure this is a valid phone number format
  const email = "shilohbridal@gmail.com";
  const [isPhoneActive, setIsPhoneActive] = useState(false);
  const [isEmailActive, setIsEmailActive] = useState(false);

  const handlePhoneClick = () => {
    setIsPhoneActive(true);
    // Create a clickable link element
    const link = document.createElement("a");
    link.href = `tel:${phoneNumber}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      setIsPhoneActive(false);
    }, 200);
  };

  const handleEmailClick = () => {
    setIsEmailActive(true);
    // Create a clickable link element
    const link = document.createElement("a");
    link.href = `mailto:${email}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      setIsEmailActive(false);
    }, 200);
  };

  const clickStyle = {
    transform: "scale(0.95)",
    transition: "transform 0.2s",
  };

  const baseStyle = {
    fontFamily: "ubuntu",
    cursor: "pointer",
    transition: "transform 0.2s",
  };

  return (
    <div
      className="d-none d-md-flex justify-content-end align-items-center px-5 py-3 mt-3"
      style={{ backgroundColor: "#8E764F" }}
    >
      <div className="d-flex flex-column flex-md-row align-items-center gap-2 gap-md-4">
        <h5
          onClick={handlePhoneClick}
          className="text-white m-0 fs-6 fw-bold"
          style={{
            ...baseStyle,
            ...(isPhoneActive ? clickStyle : {}),
          }}
        >
          <FaPhone
            style={{ transform: "rotate(100deg)", marginRight: "4px" }}
          />
          Toll-Free No: {phoneNumber}
        </h5>
        <h5
          onClick={handleEmailClick}
          className="text-white m-0 fs-6 fw-bold"
          style={{
            ...baseStyle,
            ...(isEmailActive ? clickStyle : {}),
          }}
        >
          <FaEnvelope style={{ marginRight: "9px" }} />
          {email}
        </h5>
      </div>
    </div>
  );
}
