import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {  FaSearch } from "react-icons/fa";
import { FaRegUser, FaUser } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
//icons are in local host
import shoppingcart from "../../assets/icons/shoppingcart.png";
import indiaLanguage from "../../assets/icons/india.svg";
import { useSelector } from "react-redux";
import { Drawer } from "../../ChildComponent/Drawer/Drawer";
import store from "../../Redux/store";
import { get_cart_list, get_wish_list } from "../../Redux/Slice/ProductSlice";
import { InputBox } from "../../ChildComponent/Input/Inputs";
import { toast } from "react-toastify";
import { base_url } from "../../utils/data";
import DebounceHook from "../../utils/Hook/DebouncingHook";

const logo = base_url + "upload/logo.svg";
const india = base_url + "upload/india.svg";

const shopping = base_url + "upload/shopping-cart.png";

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [timeoutId, setTimeoutId] = useState(null);
  const { wish_list, cart_list } = useSelector((state) => state.productData);

  const dropdownRef = useRef(null);

  const userId = localStorage.getItem("user_id");

  const { category_list } = useSelector((state) => state.otherData);

  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (userId) {
      store.dispatch(get_cart_list(userId));
      store.dispatch(get_wish_list(userId));
    }
  }, [userId]);

  const handleMouseEnter = useCallback(
    (index) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      setActiveIndex(index);
    },
    [timeoutId]
  );

  const handleMouseLeave = useCallback(() => {
    const id = setTimeout(() => {
      setActiveIndex(null);
    }, 300);
    setTimeoutId(id);
  }, []);

  const updateSearchResultsDebounced = useCallback(
    DebounceHook((query) => {
      fetchSearchResults(query);
    }, 500),
    []
  );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchTerm(query);
    if (event.target.value?.length > 3) {
      updateSearchResultsDebounced(query);
    }
  };

  const fetchSearchResults = async (keyword) => {
    if (keyword) {
      try {
        const formData = new FormData();
        formData.append("action", "list");
        formData.append("keyword", keyword);

        const response = await fetch(base_url + "search_web.php", {
          method: "POST",
          body: formData,
        });

        const data = await response.json(); // Parse the JSON response

        if (data.success) {
          setSearchResults(data.data); // Set the search results
        } else {
          console.error("Failed to fetch search results:", data.message);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleUserDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    navigate("/");
    setShowDropdown(false);
  };

  return (
    <>
      <nav className="d-lg-none">
        <div className="container-fluid px-3">
          <div className="d-flex justify-content-between align-items-center w-100">
            <a className="navbar-brand" href="/" onClick={() => navigate("/")}>
              <img src={logo} alt="Shiloh Bridal Logo" width="80" height="80" />
            </a>
            <button
              className="navbar-toggler border-0"
              type="button"
              onClick={() => setIsActive(!isActive)}
            >
              <FontAwesomeIcon
                icon={faBars}
                style={{ fontSize: "24px", color: "#8F1336" }}
              />
            </button>
          </div>

          <div
            className=" d-flex align-items-center w-100 my-3 position-relative"
            style={{ width: "100%", maxWidth: "100%" }}
          >
            <InputBox
              width=""
              placeholder={"Search"}
              value={searchTerm}
              onChange={handleSearchChange}
              leftIcon={
                <FaSearch
                  size={20}
                  style={{ color: "#8F1336", cursor: "default" }}
                />
              }
            />
            {/* Display search results */}
            {searchTerm?.length > 3 && (
              <ul
                className="dropdown-menu show p-2"
                style={{
                  position: "absolute",
                  top: "110%",
                  zIndex: "999",
                  left: 0,
                  minWidth: "100%",
                }}
              >
                {searchResults.length > 0 ? (
                  searchResults.map((item, index) => (
                    <li style={{ width: "100%" }} key={index}>
                      <Link
                        to={`/product/${item.id}`}
                        className="text-dark text-decoration-none"
                        onClick={() => {
                          setSearchTerm("");
                          setSearchResults([]);
                        }}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li style={{ width: "100%" }}>No results found</li>
                )}
              </ul>
            )}
          </div>

          {/* Mobile Menu - Slides from right */}

          <Drawer open={isActive} setOpen={setIsActive} title={"Menu"}>
            {/* Mobile Categories */}
            <div className="mobile-categories">
              {/* All Category */}

              {category_list?.map((v, i) => (
                <>
                  <div className="category-item mb-3" key={i}>
                    <div
                      className="d-flex justify-content-between align-items-center py-2"
                      onClick={() => toggleDropdown(i)}
                      style={{ cursor: "pointer" }}
                    >
                      <span style={{ fontSize: "16px", color: "#121212BF" }}>
                        {v?.category}
                      </span>
                      {v?.subCategories.length > 0 && (
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className={activeIndex === i ? "rotate" : ""}
                          style={{
                            transition: "transform 0.3s ease",
                            transform:
                              activeIndex === i
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                          }}
                        />
                      )}
                    </div>
                    {activeIndex === i && (
                      <ul className="list-unstyled ps-3 mt-2">
                        {v?.subCategories?.map((item, index) => (
                          <li key={index} className="py-2">
                            <div
                              onClick={() => {
                                navigate(
                                  `/ShopByCategories/${item?.id}/${v?.id}`,
                                  {
                                    state: {
                                      data: { id: item?.id, cat_id: v?.id },
                                    },
                                  }
                                );
                                setIsActive(false);
                              }}
                              className="text-decoration-none"
                              style={{ color: "#4A5568", cursor: "pointer" }}
                            >
                              {item.name}
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </>
              ))}
              <div className="category-item mb-3">
                <div
                  className="d-flex justify-content-between align-items-center py-2"
                  onClick={() => {
                    setIsActive(false);
                    navigate("/contact-us");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ fontSize: "16px", color: "#121212BF" }}>
                    Contact Us
                  </span>
                </div>
              </div>
            </div>

            {/* Mobile User Actions */}
            <div className="mobile-user-actions mt-4">
              <div className="d-flex justify-content-around align-items-center">
                <div
                  onClick={() => {
                    if (userId) {
                      setIsActive(false);
                      navigate("/wishlist");
                    } else {
                      toast.error("Please login to add items to wishlist");
                      navigate("/Signin");
                    }
                  }}
                  className="text-decoration-none"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={wish_list?.length > 0 ? solidHeart : regularHeart}
                    style={{ color: "#8F1336", fontSize: "20px" }}
                  />
                </div>
                {userId ? (
                  <DropDownData
                    setIsActive={setIsActive}
                    ref={dropdownRef}
                    handleUserDropdown={handleUserDropdown}
                    showDropdown={showDropdown}
                    setShowDropdown={setShowDropdown}
                    handleLogout={handleLogout}
                  />
                ) : (
                  <Link to="/Signin" className="text-decoration-none">
                    <img
                      src={base_url + "upload/user.png"}
                      alt="User"
                      width="20"
                    />
                  </Link>
                )}
                <div
                  // onClick={() => {
                  //   setIsActive(false);
                  //   navigate("/cart");
                  // }}
                  onClick={() => {
                    if (userId) {
                      setIsActive(false);
                      navigate("/cart");
                    } else {
                      toast.error("Please login to add items to Cart");
                      navigate("/Signin");
                    }
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  className="text-decoration-none position-relative"
                >
                  <img src={shopping} alt="Cart" width="20" />
                  {cart_list?.length > 0 && (
                    <span
                      className="badge rounded-pill"
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        backgroundColor: "#8F1336",
                        fontSize: "10px",
                      }}
                    >
                      {cart_list?.length}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar-light bg-white d-none d-lg-block">
        <div className="container-fluid px-5">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsActive(!isActive)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <a
            className="navbar-brand mx-lg-0 mx-auto"
            href="/"
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="Shiloh Bridal Logo" width="80" height="80" />
          </a>

          <div className="collapse navbar-collapse">
            <div className="d-flex align-items-center w-100 mx-5">
              <div
                className="d-flex align-items-center w-100 mx-5 position-relative"
                style={{ width: "300px", maxWidth: "300px" }}
              >
                <InputBox
                  width=""
                  placeholder={"Search"}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  leftIcon={
                    <FaSearch
                      // onClick={handleSearchSubmit}
                      size={20}
                      style={{ color: "#8F1336", cursor: "default" }}
                    />
                  }
                />
                {/* Display search results */}
                {searchTerm?.length > 3 && (
                  <ul
                    className="dropdown-menu show p-2"
                    style={{
                      position: "absolute",
                      top: "110%",
                      zIndex: "999",
                      left: 0,
                      minWidth: "300px",
                    }}
                  >
                    {searchResults.length > 0 ? (
                      searchResults.map((item, index) => (
                        <li style={{ width: "100%" }} key={index}>
                          <Link
                            style={{ color: "#8F1336" }}
                            to={`/product/${item.id}`}
                            className="text-dark text-decoration-none"
                            onClick={() => {
                              setSearchTerm("");
                              setSearchResults([]);
                            }}
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li style={{ width: "100%" }}>No results found</li>
                    )}
                  </ul>
                )}
              </div>
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
                <li className="nav-item d-flex align-items-center me-3">
                  <img
                    src={indiaLanguage}
                    className="img-fluid me-3"
                    alt="India"
                    width="30"
                    height="30"
                  />
                  <span
                    style={{
                      wordSpacing: "0.5rem",
                      color: "#121212BF",
                      fontSize: "16px",
                      fontFamily: "ubuntu",
                      fontWeight: "700",
                    }}
                  >
                    EN | INR
                  </span>
                </li>
                <li className="nav-item me-3">
                  <Link
                    className="nav-link"
                    to={!userId ? "/Signin" : "/wishlist"}
                    onClick={() => {
                      if (!userId) {
                        toast.error("Please login to add items to Cart");
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        wish_list?.length > 0 && userId
                          ? solidHeart
                          : regularHeart
                      }
                      size="md"
                      style={{ color: "#8F1336", fontSize: "25px" }}
                    />
                  </Link>
                </li>

                {userId ? (
                  <DropDownData
                    setIsActive={setIsActive}
                    ref={dropdownRef}
                    handleUserDropdown={handleUserDropdown}
                    showDropdown={showDropdown}
                    setShowDropdown={setShowDropdown}
                    handleLogout={handleLogout}
                  />
                ) : (
                  <li className="nav-item me-3">
                    <Link
                      className="nav-link"
                      to="/Signin"
                      onClick={() => navigate("/Signin")}
                    >
                      <FaRegUser className="user_icon" />
                    </Link>
                  </li>
                )}
                <li className="nav-item position-relative">
                  <Link
                    className="nav-link"
                    to={!userId ? "/Signin" : "/cart"}
                    // onClick={() => navigate("/cart")}
                    onClick={() => {
                      if (!userId) {
                        toast.error("Please login to add items to Cart");
                      }
                    }}
                  >
                    {/* <img src={shopping} alt="Cart" width="24" /> */}
                    <img src={shoppingcart} alt="Cart" width="24" />

                    {userId && cart_list?.length > 0 && (
                      <span
                        className="badge rounded-pill"
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-10px",
                          backgroundColor: "#8F1336",
                        }}
                      >
                        {cart_list?.length}
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid px-5 py-0 d-none d-lg-block">
        <div className="row justify-content-between align-items-center">
          {/* All Menu  */}

          {category_list?.map((v, i) => (
            <div
              key={i}
              className="col-auto"
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
              style={{ position: "relative" }}
            >
              <h2
                className="d-flex align-items-center m-0"
                style={{
                  cursor: "default",
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#121212BF",
                  cursor: "pointer",
                }}
              >
                {/* Bridal Collection */}
                {v?.category}
                {v?.subCategories.length > 0 && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`ms-2 ${activeIndex === i ? "rotate" : ""}`}
                    style={{
                      transition: "transform 0.3s ease",
                      transform:
                        activeIndex === i ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </h2>
              {v?.subCategories.length > 0 && (
                <ul
                  style={
                    activeIndex === i
                      ? dropdownStyles.menu
                      : dropdownStyles.menuHidden
                  }
                >
                  {v?.subCategories.length > 0 ? (
                    v?.subCategories.map((subCategory, index) => (
                      <li key={index} style={dropdownStyles.item}>
                        <div
                          // to={`/ShopByCategories`}
                          onClick={() =>
                            navigate(
                              `/ShopByCategories/${subCategory?.id}/${v?.id}`,
                              {
                                state: {
                                  data: { id: subCategory?.id, cat_id: v?.id },
                                },
                              }
                            )
                          }
                          style={dropdownStyles.link}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#F7FAFC";
                            e.target.style.color = "#718096";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "transparent";
                            e.target.style.color = "#4A5568";
                          }}
                        >
                          {subCategory.name}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li style={dropdownStyles.item}>
                      No subcategories available
                    </li>
                  )}
                </ul>
              )}
            </div>
          ))}
          <div className="col-auto">
            <h2
              onClick={() => {
                navigate("/contact-us");
              }}
              className="d-flex align-items-center m-0"
              style={{
                cursor: "default",
                fontSize: "18px",
                fontWeight: "400",
                color: "#121212BF",
                cursor: "pointer",
              }}
            >
              Contact Us
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
const dropdownStyles = {
  menu: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    minWidth: "200px",
    zIndex: 1000,
    marginTop: "0.5rem",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "0.5rem 0",
    opacity: 1,
    visibility: "visible",
    transform: "translateY(0)",
    transition: "all 0.3s ease-in-out",
    pointerEvents: "auto",
  },
  menuHidden: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
    minWidth: "200px",
    zIndex: 1000,
    marginTop: "0.5rem",
    padding: "0.5rem 0",
    opacity: 0,
    visibility: "hidden",
    transform: "translateY(-10px)",
    transition: "all 0.3s ease-in-out",
    pointerEvents: "none",
  },
  item: {
    padding: "8px 16px",
    listStyle: "none",
    transition: "background-color 0.2s ease",
    cursor: "pointer",
  },
  link: {
    color: "#4A5568",
    textDecoration: "none",
    display: "block",
    fontSize: "14px",
    fontWeight: "400",
    padding: "8px 16px",
    transition: "all 0.2s ease",
  },
  dropdownContainer: {
    position: "relative",
    paddingBottom: "0.5rem",
  },
};

const DropDownData = ({
  ref,
  handleUserDropdown,
  showDropdown,
  setShowDropdown,
  handleLogout,
  setIsActive,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <li style={{ listStyle: "none" }} className="nav-item dropdown" ref={ref}>
        <div
          className="nav-link d-flex align-items-center justify-content-center user_icon"
          style={{}}
          onClick={handleUserDropdown}
        >
          <FaUser className="user_icon" />
        </div>
        {showDropdown && (
          <ul
            className="dropdown-menu show p-1"
            style={{ position: "absolute", marginLeft: "-49px" }}
          >
            <li>
              <button
                onClick={() => {
                  navigate("/dashboard");
                  setShowDropdown(false);
                  setIsActive(false);
                }}
                className="dropdown-item"
              >
                Dashboard
              </button>
            </li>
            <li>
              <button onClick={handleLogout} className="dropdown-item">
                Logout
              </button>
            </li>
          </ul>
        )}
      </li>
    </>
  );
};

export default Navbar;
