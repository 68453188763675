import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { base_url, updateOrderData } from "../../utils/data";
import { toast } from "react-toastify";
import store from "../../Redux/store";
import { useSelector } from "react-redux";
import { get_order } from "../../Redux/Slice/UserSlice";
import moment from "moment";
import { FaChevronDown, FaEye } from "react-icons/fa";
import { Tooltip } from "antd";
import { BiEdit } from "react-icons/bi";
import { CustomModal } from "../../ChildComponent/Modal/Modal";
import { MesurmentFunction } from "../../ChildComponent/OtherComponents";
import { get_cart_list } from "../../Redux/Slice/ProductSlice";

const OrderDetails = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState("");
  const [data, setData] = useState({});

  const { id, sid } = useParams();
  const { user_id, order, order_loading } = useSelector(
    (state) => state.userData
  );
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    if (sid && id) {
      updateOrderData(id, sid);
    }
  }, [sid]);

  useEffect(() => {
    if (userId) {
      store.dispatch(get_order({ id, user_id: userId }));
      store.dispatch(get_cart_list(userId));
    }
  }, [userId]);

  return (
    <div className="container-fluid order_details">
      <div>
        <div style={{ flex: 2 }} className="d-flex flex-column gap-3">
          {order?.products?.map((v, i) => (
            <Card
              key={i}
              className="d-flex flex-column flex-md-row justify-content-start gap-2 gap-md-4 p-4 px-3 shadow-sm"
            >
              <div
                className="d-flex flex-row gap-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/product/${v?.product_id}`)}
              >
                <img
                  src={v?.image}
                  alt=""
                  style={{
                    height: "auto",
                    width: "100px",
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                />
                <div className="d-flex gap-2 flex-column">
                  <div>#{v?.itemcode}</div>
                  <div className="fw-bold " style={{ maxWidth: "200px" }}>
                    {/* naveen test naveen test naveen test */}
                    {v?.name} {v?.nameitem1} {v?.nameitem2}
                  </div>
                  <div>Rs. {v?.price}</div>
                  <div>Quantity : {v?.count} Nos</div>
                </div>
              </div>
              <div>
                {/* <div
                  onClick={() => setOpen(!open)}
                  className="d-flex justify-content-start align-items-center gap-3 my-3"
                >
                  <div
                    style={{
                      color: "#8F1336",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Customization & Measuring Details
                  </div>
                  <FaChevronDown
                    style={{
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                    cursor={"pointer"}
                    color="#8F1336"
                  />
                </div> */}
                <div
                  className="d-flex  flex-column  gap-2"
                  style={{
                    padding: "0px 0px",
                    width: "100%",
                  }}
                >
                  <div className="d-flex w-100 flex-column  gap-2">
                    <div className="d-flex justify-content-between w-100 ">
                      <div className="fw-bold">Customization</div>
                      <div></div>
                    </div>
                    <div className="d-flex justify-content-start flex-column gap-2">
                      <div className="d-flex ">
                        <div style={{ flex: 1 }} className=" text-start">
                          Color :
                        </div>
                        <div style={{ flex: 1 }} className=" text-start">
                          {v?.color}
                        </div>
                      </div>

                      {v?.customization?.map((item, i) => (
                        <div className="d-flex " key={i}>
                          <div style={{ flex: 1 }} className=" text-start">
                            {item?.customization_category_name} :
                          </div>
                          <div style={{ flex: 1 }} className=" text-start">
                            {item?.customization_name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 flex-column  gap-2">
                  <div className="d-flex justify-content-between w-100 gap-2">
                    <div className="fw-bold">Your Measurements</div>
                    <div className="d-flex ">
                      <Tooltip title={"View"}>
                        <FaEye
                          onClick={() => {
                            setType("view");
                            setIsModalOpen(true);
                            setData(v);
                          }}
                          size={25}
                          cursor={"pointer"}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
        <Card
          style={{ flex: 1 }}
          className="d-flex flex-column gap-5 justify-content-start p-3"
        >
          <div className="d-flex flex-column gap-1 justify-content-start">
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Order#</div>
              <div style={{ flex: 1, textAlign: "right" }}>
                {order?.Rpayorderid}
              </div>
            </div>
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Date</div>
              <div style={{ flex: 1, textAlign: "right" }}>
                {" "}
                {moment(order?.datetime).format("DD-MMM-YYYY hh:mm A")}
              </div>
            </div>
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Payment Mode</div>
              <div style={{ flex: 1, textAlign: "right" }}>Card</div>
            </div>
            {!order?.shipping_details?.find(
              (v, i) => v?.title == "Delivered"
            ) && (
              <div className="d-flex flex-row gap-3 justify-content-start">
                <div style={{ flex: 1 }}>Estimated Delivery Date</div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  {order?.estimate_date}
                </div>
              </div>
            )}

            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Sub Total</div>
              <div style={{ flex: 1, textAlign: "right" }}>
                Rs. {Number(order?.product_amount?.MRP).toFixed(2)}
              </div>
            </div>
            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Discount on MRP</div>
              <div style={{ flex: 1, textAlign: "right" }}>
                - Rs.{" "}
                {Number(order?.product_amount?.DISCOUNT_ON_MRP).toFixed(2)}
              </div>
            </div>
            {order?.product_amount?.promocode_amount && (
              <div className="d-flex flex-row gap-3 justify-content-start">
                <div style={{ flex: 1 }}>Coupan Discount</div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  - Rs.{" "}
                  {Number(order?.product_amount?.promocode_amount).toFixed(2)}
                </div>
              </div>
            )}

            <div className="d-flex flex-row gap-3 justify-content-start">
              <div style={{ flex: 1 }}>Shipping Charges </div>
              <div style={{ flex: 1, textAlign: "right" }}>
                Rs. {Number(order?.product_amount?.ship_charges).toFixed(2)}
              </div>
            </div>

            <div className="d-flex flex-row gap-3 justify-content-start fw-bold">
              <div style={{ flex: 1 }}>Total Order Amt. </div>
              <div style={{ flex: 1, textAlign: "right" }}>
                Rs. {order?.product_amount?.totalamount}
              </div>
            </div>
          </div>
          <Accordion defaultActiveKey={"0"}>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="custom-header">
                Shipping Traiking
              </Accordion.Header>
              <Accordion.Body>
                {order?.shipping_details?.map((v, i) => (
                  <div key={i} style={{ display: "flex" }}>
                    <div style={{ flex: 15, fontWeight: "bold" }}>
                      {v?.title}{" "}
                    </div>
                    <div style={{ flex: 1 }}>:</div>
                    <div style={{ flex: 30 }}>
                      {moment(v?.date).format("DD-MMM-YYYY hh:mm A")}
                      {v?.shipper_name && (
                        <div>
                          ( By - <b>{v?.shipper_name}</b>)
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="d-flex flex-column gap-1">
            <h3>Shipping Address</h3>
            <p className="mb-0 fw-bold">{order?.shipping_address?.fullname}</p>
            <p className="mb-0">{order?.shipping_address?.email}</p>
            <p className="mb-0">{order?.shipping_address?.mobilenumber}</p>
            <p className="mb-0">{order?.shipping_address?.shipping_address} </p>
            <p className="mb-0">
              {order?.shipping_address?.city},{" "}
              {order?.shipping_address?.STATE_OR_UT_NAME}{" "}
            </p>
            <p className="mb-0">India - {order?.shipping_address?.pincode} </p>
          </div>
        </Card>
      </div>

      {isModalOpen && (
        <CustomModal
          title={
            type == "edit" ? "Edit Custom Mesurement" : "View Custom Mesurement"
          }
          width="100%"
          height="100%"
          open={isModalOpen}
          handleCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <MesurmentFunction
            disabled={type == "view" ? true : false}
            measurements={data}
            // handleSubmit={handleSubmitMesur}
            // handleChange={handleChange}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default OrderDetails;
