import React, { useState } from 'react';
import { base_url } from '../../utils/data';

const newsletter = base_url + 'upload/newsletter.svg';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email || !email.includes('@')) {
      setStatus({ type: 'error', message: 'Please enter a valid email address' });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('email', email);
    formData.append('action', 'subscribe');

    try {
      const response = await fetch(base_url + 'subscribe.php', {
        method: 'POST',
        body: formData
      });
      
      const data = await response.json();
      
      if (data.success) {
        setStatus({ type: 'success', message: data.msg });
        setEmail('');
      } else {
        setStatus({ type: 'error', message: data.msg });
      }
    } catch (error) {
      setStatus({ type: 'error', message: 'An error occurred. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-wrapper">
      <style>
        {`
          .main-wrapper {
            overflow-x: hidden;
            width: 100%;
          }

          .custom-container {
            padding: 1rem 3rem 0;
            margin: 1rem auto 0;
          }

          .custom-placeholder::placeholder {
            color: white;
            opacity: 1;
          }
          
          .newsletter-row {
            background-color: #8E764F;
            margin: 0;
            width: 100%;
          }

          .newsletter-img {
            width: 882px;
            max-width: 100%;
            height: auto;
            object-fit: cover;
            display: block;
          }

          .status-message {
            padding: 8px;
            border-radius: 4px;
            margin-bottom: 1rem;
            font-size: 14px;
          }

          .status-message.error {
            background-color: rgba(220, 38, 38, 0.1);
            color: #ffffff;
            border: 1px solid rgba(220, 38, 38, 0.2);
          }

          .status-message.success {
            background-color: rgba(34, 197, 94, 0.1);
            color: #ffffff;
            border: 1px solid rgba(34, 197, 94, 0.2);
          }

          @media (max-width: 1200px) {
            .newsletter-content {
              padding: 4rem !important;
            }
            .newsletter-title {
              font-size: 36px !important;
            }
          }

          @media (max-width: 991px) {
            .custom-container {
              padding: 1rem 2rem;
            }
            .newsletter-content {
              padding: 3rem !important;
            }
          }

          @media (max-width: 768px) {
            .custom-container {
              padding: 1rem 1rem;
              margin: 0.5rem auto;
            }
            .newsletter-content {
              padding: 2rem !important;
            }
            .newsletter-title {
              font-size: 28px !important;
              text-align: center;
            }
            .newsletter-input {
              font-size: 16px !important;
              width: 100% !important;
            }
            .newsletter-text {
              font-size: 14px !important;
              text-align: center;
            }
            .button-wrapper {
              text-align: center;
            }
            .newsletter-img {
              width: 100%;
              max-height: none;
              object-fit: cover;
            }
          }

          @media (max-width: 760px) and (min-width: 400px) {
            .custom-container {
              padding: 0.5rem;
              margin: 0.5rem auto;
              max-width: 100% !important;
              width: 100%;
            }
            .newsletter-row {
              margin: 0;
              width: 100%;
            }
            .newsletter-img {
              width: 100%;
              max-height: none;
              object-fit: cover;
            }
          }

          @media (max-width: 576px) {
            .custom-container {
              padding: 0.5rem;
              margin: 0.5rem auto;
              max-width: 95%;
            }
            .newsletter-content {
              padding: 1.5rem !important;
            }
            .newsletter-title {
              font-size: 24px !important;
            }
            .newsletter-input {
              padding: 8px !important;
            }
            .btn-subscribe {
              padding: 8px 16px !important;
              font-size: 14px !important;
            }
            .newsletter-row {
              margin: 0 auto;
            }
          }
        `}
      </style>
      <div className="custom-container">
        <div className="row align-items-center newsletter-row">
          <div className="col-12 col-md-6 mb-3 mb-md-0 newsletter-content" style={{padding: "6rem"}}>
            <h2 
              className="mb-3 newsletter-title" 
              style={{ 
                fontSize: '40px', 
                fontFamily: 'Playfair Display', 
                color: '#ffffff', 
                fontWeight: "600" 
              }}
            >
              Subscribe to Our Newsletter
            </h2>
            <input
              type="email"
              className="form-control mb-3 custom-placeholder newsletter-input"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ 
                padding: '10px', 
                borderColor: '#ffffff', 
                borderRadius: '8px', 
                backgroundColor: "#8E764F", 
                fontSize: "20px", 
                fontWeight: "400", 
                fontFamily: "ubuntu" 
              }}
            />
            {status.message && (
              <div className={`status-message ${status.type}`}>
                {status.message}
              </div>
            )}
            <p className="text-white mb-3 p-0 newsletter-text">
              Join Our Bridal Community for Special Updates and Style Guides
            </p>
            <div className="button-wrapper text-md-left">
              <button
                type="button"
                className="btn btn-subscribe"
                onClick={handleSubmit}
                disabled={isLoading}
                style={{
                  backgroundColor: '#12121285',
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '10px 20px',
                  fontWeight: "700",
                  fontSize: "16px",
                  transition: 'all 0.3s ease',
                  cursor: isLoading ? 'not-allowed' : 'pointer',
                  opacity: isLoading ? 0.7 : 1
                }}
              >
                {isLoading ? 'Subscribing...' : 'Subscribe'}
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6 p-0">
            <img
              src={newsletter}
              alt="Newsletter"
              className="newsletter-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;





// this code is subscribe and unsubscribe



// import React, { useState } from 'react';

// const newsletter = base_url + 'upload/newsletter.svg';

// const Newsletter = () => {
//   const [email, setEmail] = useState('');
//   const [status, setStatus] = useState({ type: '', message: '' });
//   const [isLoading, setIsLoading] = useState(false);
//   const [mode, setMode] = useState('subscribe'); // 'subscribe' or 'unsubscribe'

//   const handleSubmit = async () => {
//     if (!email || !email.includes('@')) {
//       setStatus({ type: 'error', message: 'Please enter a valid email address' });
//       return;
//     }

//     setIsLoading(true);
//     const formData = new FormData();
//     formData.append('email', email);
//     formData.append('action', mode);

//     try {
//       const response = await fetch(base_url + 'subscribe.php', {
//         method: 'POST',
//         body: formData
//       });
      
//       const data = await response.json();
      
//       if (data.success) {
//         setStatus({ type: 'success', message: data.msg });
//         setEmail('');
//       } else {
//         setStatus({ type: 'error', message: data.msg });
//       }
//     } catch (error) {
//       setStatus({ 
//         type: 'error', 
//         message: 'An error occurred. Please try again.' 
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const toggleMode = () => {
//     setMode(mode === 'subscribe' ? 'unsubscribe' : 'subscribe');
//     setStatus({ type: '', message: '' }); // Clear any previous status messages
//   };

//   return (
//     <div className="main-wrapper">
//       <style>
//         {`
//           .main-wrapper {
//             overflow-x: hidden;
//             width: 100%;
//           }

//           .custom-container {
//             padding: 1rem 3rem;
//             margin: 1rem auto;
//           }

//           .custom-placeholder::placeholder {
//             color: white;
//             opacity: 1;
//           }
          
//           .newsletter-row {
//             background-color: #8E764F;
//             margin: 0;
//             width: 100%;
//           }

//           .newsletter-img {
//             width: 882px;
//             max-width: 100%;
//             height: auto;
//             object-fit: cover;
//             display: block;
//           }

//           .status-message {
//             padding: 8px;
//             border-radius: 4px;
//             margin-bottom: 1rem;
//             font-size: 14px;
//           }

//           .status-message.error {
//             background-color: rgba(220, 38, 38, 0.1);
//             color: #ffffff;
//             border: 1px solid rgba(220, 38, 38, 0.2);
//           }

//           .status-message.success {
//             background-color: rgba(34, 197, 94, 0.1);
//             color: #ffffff;
//             border: 1px solid rgba(34, 197, 94, 0.2);
//           }

//           .mode-toggle {
//             color: #ffffff;
//             text-decoration: underline;
//             cursor: pointer;
//             background: none;
//             border: none;
//             padding: 0;
//             margin: 0 0 1rem;
//             font-size: 14px;
//           }

//           .mode-toggle:hover {
//             opacity: 0.8;
//           }

//           @media (max-width: 1200px) {
//             .newsletter-content {
//               padding: 4rem !important;
//             }
//             .newsletter-title {
//               font-size: 36px !important;
//             }
//           }

//           @media (max-width: 991px) {
//             .custom-container {
//               padding: 1rem 2rem;
//             }
//             .newsletter-content {
//               padding: 3rem !important;
//             }
//           }

//           @media (max-width: 768px) {
//             .custom-container {
//               padding: 1rem 1rem;
//               margin: 0.5rem auto;
//             }
//             .newsletter-content {
//               padding: 2rem !important;
//             }
//             .newsletter-title {
//               font-size: 28px !important;
//               text-align: center;
//             }
//             .newsletter-input {
//               font-size: 16px !important;
//               width: 100% !important;
//             }
//             .newsletter-text {
//               font-size: 14px !important;
//               text-align: center;
//             }
//             .button-wrapper {
//               text-align: center;
//             }
//             .newsletter-img {
//               width: 100%;
//               max-height: none;
//               object-fit: cover;
//             }
//           }

//           @media (max-width: 760px) and (min-width: 400px) {
//             .custom-container {
//               padding: 0.5rem;
//               margin: 0.5rem auto;
//               max-width: 100% !important;
//               width: 100%;
//             }
//             .newsletter-row {
//               margin: 0;
//               width: 100%;
//             }
//             .newsletter-img {
//               width: 100%;
//               max-height: none;
//               object-fit: cover;
//             }
//           }

//           @media (max-width: 576px) {
//             .custom-container {
//               padding: 0.5rem;
//               margin: 0.5rem auto;
//               max-width: 95%;
//             }
//             .newsletter-content {
//               padding: 1.5rem !important;
//             }
//             .newsletter-title {
//               font-size: 24px !important;
//             }
//             .newsletter-input {
//               padding: 8px !important;
//             }
//             .btn-subscribe {
//               padding: 8px 16px !important;
//               font-size: 14px !important;
//             }
//             .newsletter-row {
//               margin: 0 auto;
//             }
//           }
//         `}
//       </style>
//       <div className="custom-container">
//         <div className="row align-items-center newsletter-row">
//           <div className="col-12 col-md-6 mb-3 mb-md-0 newsletter-content" style={{padding: "6rem"}}>
//             <h2 
//               className="mb-3 newsletter-title" 
//               style={{ 
//                 fontSize: '40px', 
//                 fontFamily: 'Playfair Display', 
//                 color: '#ffffff', 
//                 fontWeight: "600" 
//               }}
//             >
//               {mode === 'subscribe' ? 'Subscribe to Our Newsletter' : 'Unsubscribe from Newsletter'}
//             </h2>
//             <input
//               type="email"
//               className="form-control mb-3 custom-placeholder newsletter-input"
//               placeholder="Email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               style={{ 
//                 padding: '10px', 
//                 borderColor: '#ffffff', 
//                 borderRadius: '8px', 
//                 backgroundColor: "#8E764F", 
//                 fontSize: "20px", 
//                 fontWeight: "400", 
//                 fontFamily: "ubuntu" 
//               }}
//             />
//             {status.message && (
//               <div className={`status-message ${status.type}`}>
//                 {status.message}
//               </div>
//             )}
//             <p className="text-white mb-3 p-0 newsletter-text">
//               {mode === 'subscribe' 
//                 ? 'Join Our Bridal Community for Special Updates and Style Guides'
//                 : 'We\'re sorry to see you go. Enter your email to unsubscribe.'}
//             </p>
//             <div className="button-wrapper text-md-left">
//               <button
//                 type="button"
//                 className="btn btn-subscribe"
//                 onClick={handleSubmit}
//                 disabled={isLoading}
//                 style={{
//                   backgroundColor: '#12121285',
//                   color: '#ffffff',
//                   border: 'none',
//                   borderRadius: '4px',
//                   padding: '10px 20px',
//                   fontWeight: "700",
//                   fontSize: "16px",
//                   transition: 'all 0.3s ease',
//                   cursor: isLoading ? 'not-allowed' : 'pointer',
//                   opacity: isLoading ? 0.7 : 1
//                 }}
//               >
//                 {isLoading 
//                   ? (mode === 'subscribe' ? 'Subscribing...' : 'Unsubscribing...') 
//                   : (mode === 'subscribe' ? 'Subscribe' : 'Unsubscribe')}
//               </button>
//             </div>
//             <button 
//               onClick={toggleMode} 
//               className="mode-toggle mt-3"
//             >
//               {mode === 'subscribe' 
//                 ? 'Want to unsubscribe instead?' 
//                 : 'Want to subscribe instead?'}
//             </button>
//           </div>
//           <div className="col-12 col-md-6 p-0">
//             <img
//               src={newsletter}
//               alt="Newsletter"
//               className="newsletter-img"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Newsletter;