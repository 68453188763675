import React from "react";

export const Button = ({
  title,
  w,
  icon,
  onClick,
  fs = "16px",
  disabled,
  bg,
  color,
}) => {
  return (
    <button
      type="button"
      className="btn"
      style={{
        width:w,
        fontSize: fs,
        padding: "7px 18px",
        backgroundColor: bg ? bg : "#ffffff",
        color: color ? color : "#8F1336",
        fontFamily: "Ubuntu",
        borderColor: "#8F1336",
        borderStyle: "solid",
        borderRadius: "6px",
        fontWeight: "700",
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {title} {icon ? icon : ""}
      {/* View All  */}
      {/* <span style={{ fontSize: "25px" }}>→</span> */}
    </button>
  );
};
