import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { get_default_address } from "../../Redux/Slice/UserSlice";
import store from "../../Redux/store";
import { useSelector } from "react-redux";
import { FaRegCircleUser } from "react-icons/fa6";
import { Button } from "../../ChildComponent/Button/Button";
import { base_url } from "../../utils/data";

const cardData = [
  {
    imgSrc: base_url + "upload/order.png",
    title: "Your Order",
    text: "Track orders for seamless delivery updates.",
    path: "/dashboard/orders",
  },
  {
    imgSrc: base_url + "upload/address.png",
    title: "Your Address",
    text: "Save your addresses for a faster, hassle-free checkout every time.",
    path: "/dashboard/address",
  },
  {
    imgSrc: base_url + "upload/userdetails.png",
    icon: true,
    iconData: (
      <FaRegCircleUser
        className="mx-auto mt-3"
        size={65}
        color="rgb(142, 19, 54)"
      />
    ),
    title: "Profile Details",
    text: "Manage your personal and account information.",
    path: "/dashboard/PersonalDetails",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();

  // Function to handle card click and navigate to the appropriate path
  const handleCardClick = (path) => {
    navigate(path);
  };
  const { user_id, user_details } = useSelector((state) => state.userData);

  return (
    <Row className="dash_main">
      <Col md={12} className="mb-3">
        <Card className="d-flex align-items-left p-4 px-5 shadow-sm ">
          <div className=" profile_main">
            <div className="d-flex gap-3 align-items-center">
              <FaRegCircleUser size={65} color="rgb(142, 19, 54)" />
              <h3 style={{ color: "#121212BF", textAlign: "center" }}>
                {user_details?.fullname}
              </h3>
            </div>
            <Button onClick={()=>navigate('/dashboard/PersonalDetails')} title="Edit" />
          </div>
        </Card>
      </Col>
      {cardData.map((card, index) => (
        <Col md={4} key={index} className="mb-3">
          <Card
            className="text-center shadow-sm h-100"
            onClick={() => handleCardClick(card.path)}
            style={{ cursor: "pointer" }}
          >
            {card?.icon ? (
              card?.iconData
            ) : (
              <Card.Img
                variant="top"
                src={card.imgSrc}
                className="mx-auto mt-3"
                style={{ width: "65px", height: "65px" }}
              />
            )}
            <Card.Body>
              <Card.Title style={{ fontSize: "24px" }}>{card.title}</Card.Title>
              <Card.Text className="small" style={{ fontSize: "12px" }}>
                {card.text}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Dashboard;
