import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { InputBox } from "../../ChildComponent/Input/Inputs";
import { CiCircleInfo } from "react-icons/ci";
import { Tooltip } from "antd";
import { base_url } from "../../utils/data";
import { toast } from "react-toastify";

const logo = base_url + "upload/logo.svg";

export default function Registration() {
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError] = useState({});

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();

    if (Object.keys(errors)?.length == 0) {
      const isEmail = /\S+@\S+\.\S+/.test(emailOrMobile);
      const isMobile = /^\d+$/.test(emailOrMobile);
      try {
        const formData = new FormData();
        formData.append("fullname", fullname);
        formData.append(isEmail ? "email" : "mobile", emailOrMobile);
        formData.append("password", password);
        formData.append("confirm_password", confirm_password);

        fetch(base_url + "registration.php", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              toast.success(data?.msg || data?.message);
              navigate("/signin");
            } else {
              toast.error(data?.msg || data?.message);
              setError(data.message || "Something went wrong.");
            }
          })
          .catch((error) => {
            setError("Failed to register. Please try again later.");
            console.error("Error:", error);
          });
      } catch (error) {
        console.log("error ====", error);
      }
    } else {
      setError({ ...error, ...errors });
    }
  };

  const validate = () => {
    const obj = {};
    if (!fullname || fullname?.trim()?.length == 0) {
      obj.name_err = "Please Enter Full Name";
    } else if (fullname?.length < 3) {
      obj.name_err = "Full Name must have min 3 charactors";
    }
    if (!emailOrMobile || emailOrMobile?.trim()?.length == 0) {
      obj.email_err = "Please Enter Mobile or Email";
    } else if (/^\d+$/.test(emailOrMobile)) {
      if (!/^\d{10}$/.test(emailOrMobile)) {
        obj.email_err = "A valid 10-digit mobile number is required.";
      }
    } else if (!/\S+@\S+\.\S+/.test(emailOrMobile)) {
      obj.email_err = "A valid email address is required.";
    }
    if (!password || password?.trim()?.length == 0) {
      obj.password_err = "Please Enter Password";
    } else if (password?.trim()?.length < 8) {
      obj.password_err = "Password At least 8 characters";
    }

    if (!confirm_password || confirm_password?.trim()?.length == 0) {
      obj.conf_pass_err = "Please Enter Confirm Password";
    } else if (confirm_password != password) {
      obj.conf_pass_err = "Password dosont match";
    }

    return obj;
  };

  return (
    <div
      className={`container-fluid min-vh-100 d-flex align-items-center ${
        window.innerWidth <= 760
          ? "p-1 justify-content-center"
          : "p-5 justify-content-end"
      }`}
      style={{
        backgroundImage: `url(${base_url}upload/login_banner.jpeg)`,

        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="col-11 col-xl-4 col-lg-5 col-md-7 col-sm-12">
        <div
          className={`bg-white  rounded shadow 
          ${window.innerWidth <= 760 ? "p-3" : "p-5 "}
          `}
        >
          <div className="text-center mb-4">
            <Link to="/">
              <img
                src={logo}
                className="logo-image"
                alt="Logo"
                style={{ maxWidth: "150px", cursor: "pointer" }}
              />
            </Link>
            <h2 className="mt-3" style={{ fontSize: "1.5rem" }}>
              Sign Up
            </h2>
          </div>

          <form
            onSubmit={handleSubmit}
            className="d-flex gap-2 gap-lg-3 flex-column"
          >
            <InputBox
              error={error?.name_err}
              value={fullname}
              onChange={(e) => {
                const value = e.target.value
                  ?.trimStart()
                  .replace(/[^a-zA-Z\s]/g, "")
                  .replace(/\s+/g, " ");

                if (value?.length <= 30) {
                  setFullName(value);
                  setError({ ...error, name_err: "" });
                }
              }}
              placeholder={"Full Name"}
            />
            <InputBox
              error={error?.email_err}
              value={emailOrMobile}
              onChange={(e) => {
                setEmailOrMobile(e.target.value.trim());
                setError({ ...error, email_err: "" });
              }}
              placeholder={"Email or Phone Number"}
            />
            <InputBox
              pass={true}
              error={error?.password_err}
              value={password}
              // onChange={(e) => setPassword(e.target.value)}
              onChange={(e) => {
                setPassword(e.target.value);
                setError({ ...error, password_err: "" });
              }}
              placeholder={"Password"}
              mess={"At least 8 characters"}
              rightIcon={
                <>
                  {/* <Tooltip className="At least 8 characters">
                    <CiCircleInfo size={20} />
                  </Tooltip> */}
                </>
              }
            />
            <InputBox
              pass={true}
              error={error?.conf_pass_err}
              value={confirm_password}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError({ ...error, conf_pass_err: "" });
              }}
              placeholder={"Confirm Password"}
              rightIcon={
                <>
                  {/* <Tooltip className="At least 8 characters">
                    <CiCircleInfo size={20} />
                  </Tooltip> */}
                </>
              }
            />
            <button
              type="submit"
              className="btn btn w-100 mb-3 p-2"
              style={{
                background: "#902637",
                border: "none",
                height: "40px",
                fontSize: "18px",
                color: "#fff",
              }}
            >
              Sign Up
            </button>
          </form>

          <p className="text-center mt-3">
            Already have an account?{" "}
            <Link to="/signin" className="text-decoration-none">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

{
  /* <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <p>Full Name</p>
              <input
                type="text"
                className="form-control py-2"
                id="fullname"
                value={fullname}
                onChange={(e) => setFullName(e.target.value)}
                required
                style={{ border: "2px solid #405c7cb8", marginLeft: "0px" }}
              />
            </div>
            <div className="mb-3">
              <p>Email or Phone Number</p>
              <input
                type="text"
                className="form-control py-2"
                id="emailOrMobile"
                value={emailOrMobile}
                onChange={(e) => setEmailOrMobile(e.target.value)}
                required
                style={{ border: "2px solid #405c7cb8", marginLeft: "0px" }}
              />
            </div>
            <div className="mb-3">
              <p>Password</p>
              <input
                type="password"
                className="form-control py-2"
                id="password"
                placeholder="At least 8 characters"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ border: "2px solid #405c7cb8", marginLeft: "0px" }}
              />
            </div>
            <div className="mb-3">
              <p>Confirm Password</p>
              <input
                placeholder="Confirm Password"
                type="password"
                className="form-control py-2"
                id="confirmPassword"
                value={confirm_password}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={{ border: "2px solid #405c7cb8", marginLeft: "0px" }}
              />
            </div>

            <button
              type="submit"
              className="btn btn w-100 mb-3 p-2"
              style={{
                background: "#902637",
                border: "none",
                height: "40px",
                fontSize: "18px",
                color: "#fff",
              }}
            >
              Sign Up
            </button>
          </form> */
}
