import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import {
  FaStar,
  FaRegStar,
  FaStarHalfAlt,
  FaFilter,
  FaArrowLeft,
} from "react-icons/fa";
import "./ClotheMenu.css";
import { base_url } from "../../utils/data";
import { Filter } from "./Filter";
import { Drawer } from "../../ChildComponent/Drawer/Drawer";
import { useSelector } from "react-redux";
import {
  get_filter_data,
  get_product_list,
  get_wish_list,
} from "../../Redux/Slice/ProductSlice";
import store from "../../Redux/store";
import { toast } from "react-toastify";
import { ProductCardSub } from "../../ChildComponent/OtherComponents";

export default function ClotheMenu({ state }) {
  const [openMenus, setOpenMenus] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const userId = localStorage.getItem("user_id");
  const { product_list } = useSelector((state) => state.productData);
  const [filterData, setFilterData] = useState([]);

  console.log("filterData ===", filterData);

  const { filter_data } = useSelector((state) => state.productData);

  const { cat_id, id } = state;

  useEffect(() => {
    const allCheckedData = filterData
      ? filterData
          .map((category) => ({
            label: category.label,
            checkedItems: category.data.filter((item) => item.checked),
          }))
          .filter((category) => category.checkedItems.length > 0) // Remove categories with no checked items
      : [];
    store.dispatch(
      get_product_list({
        ...obj,
        color: allCheckedData?.filter((v, i) => v?.label == "color")?.[0] || {},
        fabric:
          allCheckedData?.filter((v, i) => v?.label == "fabric")?.[0] || {},
        size: allCheckedData?.filter((v, i) => v?.label == "size")?.[0] || {},
        price: allCheckedData?.filter((v, i) => v?.label == "price")?.[0] || {},
      })
    );
  }, [filterData]);

  useEffect(() => {
    setFilterData(filter_data);
  }, [filter_data]);

  // const obj = { cat_id: state?.cat_id, id: state?.id };

  const obj = { cat_id, id };

  useEffect(() => {
    store.dispatch(get_wish_list(userId));
  }, [userId]);

  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(get_product_list(obj));
    store.dispatch(get_filter_data(obj));
  }, [id, cat_id]);

  const handleMenuToggle = (index) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // if (product_list_loading) {
  //   return <div>Loading...</div>; // Loading state
  // }

  return (
    <div className="container-fluid product_main">
      {/* Content here */}
      <div className="row" style={{ flexDirection: "row" }}>
        {/* <Filter openMenus={openMenus} handleMenuToggle={handleMenuToggle} /> */}

        <div className="filter_desktop col-lg-3 col-md-12">
          <Filter
            filterData={filterData}
            setFilterData={setFilterData}
            filter_data={filter_data}
            obj={obj}
            openMenus={openMenus}
            handleMenuToggle={handleMenuToggle}
          />
        </div>
        <div className="filter_menu mb-4 px-3 align-items-center">
          <div className="d-flex gap-2 align-items-center justify-content-center ">
            <FaArrowLeft onClick={() => navigate(-1)} size={20} />
            <p className="mb-0 fs-3">Filter</p>
          </div>
          <FaFilter
            size={20}
            onClick={() => setOpenDrawer(!openDrawer)}
            color={"#8F1336"}
          />
        </div>

        {/* Right Side: Product Display */}
        <div className="col-lg-9 col-md-12 product_list_main">
          <div className="row product_list">
            {product_list.map((product, i) => (
              <ProductCardSub
                className={"product-card h-100"}
                imgH={
                  "auto"
                  // window.innerWidth <= 350
                  //   ? "350px"
                  //   : window.innerWidth <= 370
                  //   ? "230px"
                  //   : window.innerWidth <= 400
                  //   ? "300px"
                  //   : window.innerWidth <= 560
                  //   ? "250px"
                  //   : window.innerWidth <= 1000
                  //   ? "400px"
                  //   : "500px"
                }
                key={i}
                product={product}
                // ofit={window?.innerWidth >= 1600 ? "contain":"cover"}
                // ofit={window?.innerWidth >= 1600 ? "contain" : window?.innerWidth >= 500? "contain" : "cover" }
                ofit={"cover"}
              />
            ))}
          </div>
        </div>

        {openDrawer && (
          <Drawer
            open={openDrawer}
            setOpen={setOpenDrawer}
            title={""}
            size={"350px"}
          >
            <Filter
              filterData={filterData}
              setFilterData={setFilterData}
              filter_data={filter_data}
              obj={obj}
              openMenus={openMenus}
              handleMenuToggle={handleMenuToggle}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
}
