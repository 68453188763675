import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function NavHeading() {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
      <div
  className="col-12 col-md-auto d-flex justify-content-center align-items-center pt-2"
  style={{  backgroundColor: "#ffffff" }}
>
  <h2
    // className="mb-1"
    style={{
      fontSize: "18px",
      color: "#8F1336",
      fontFamily: "Ubuntu",
      fontWeight: "bold",
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // subtle shadow for a more interactive look
      transition: "transform 0.2s ease", // smooth animation on hover
      marginBottom:"0"
    }}
  >
    Shipping Worldwide
  </h2>


        </div>
      </div>
    </div>
  );
}



